<template>
    <div :is="layout">
        <v-container fluid>
            <v-row>
                <v-col cols="3">
                    <v-text-field @keypress.enter="doGet(1)" v-model="search_info.number"
                                  :label="$t('airport_security.number')"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-btn  @click="doGet(1)" large color="secondary2">{{$t('search')}}</v-btn>
                </v-col>
                <v-col cols="1"  offset="5">
                   <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                         <v-btn v-bind="attrs"
                        v-on="on"
                        small
                        fab
                         class="float-right mr-4"
                        color="secondary2"
                        @click="selectdialog=true"
                         >
                        <i class="airport icon-gantetu"
                        style='font-size: 25px !important;'></i>
                    </v-btn>
                </template>
                <span>{{$t('airport_security.gantt')}}</span>
            </v-tooltip>
                </v-col>
            </v-row>
            <!-- <v-data-table
                    :server-items-length="list_count"
                    :headers="headers"
                    :items="list"
                    :items-per-page="10"
                    :loading-text="$t('data_loading')"
                    :loading="table_loading"
                    @pagination="changePagination"
                    class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar
                            flat
                    >
                        <v-toolbar-title>{{$t('airport_security.scheduler_title')}}</v-toolbar-title>
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-btn @click="selectdialog=true">preview view</v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.start_point_id="{item}">
                    {{get_start_point_name(item)}}
                </template>
                <template v-slot:item.end_point_id="{item}">
                    {{get_end_point_name(item)}}
                </template>
                <template v-slot:item.is_end="{item}">
                    {{item.is_end ? '是' : '否'}}
                </template>
                <template v-slot:item.airport_id="{item}">
                    {{get_airport_name(item)}}
                </template>
                <template v-slot:item.car_id="{item}">
                    {{get_car_name(item)}}
                </template>
                <template v-slot:item.start_time="{item}">
                    {{formateDate(item.start_time*1000)}}
                </template>
                <template v-slot:item.operation="{item}">
                    <v-btn @click="edit(item)" small text
                    >{{$t('edit')}}
                    </v-btn
                    >
                    <v-btn @click="del(item.id)" small text> {{$t('delect')}}</v-btn>
                </template>
            </v-data-table> -->
            <table-list :list="list" :listCount="list_count" @change="changePagination">
                <template #icon>
                    <i class="airport icon-paibanbiao icon_font"></i>
                </template>
                <template #item="{item}">
                    <div style="margin-bottom:10px"> {{get_airport_name(item)}}</div>
                    <div class="small_font">{{$t('airport_security.car_id')}}: {{get_car_name(item)}}</div>
                    <div class="small_font">{{$t('airport_security.start_point_id')}}: {{get_start_point_name(item)}}</div>
                    <div class="small_font">{{$t('airport_security.end_point_id')}}: {{get_end_point_name(item)}}</div>
                </template>
                <template #active="{item}">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   color="secondary"
                                   outlined
                                   text
                                   @click="edit(item)"
                                   v-on="on" x-small fab>
                                <i class="airport small-font icon-bianji "
                                   style='font-size: 18px !important; color: #b5bac1'></i>
                            </v-btn>
                        </template>
                        <span>{{$t('edit')}}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   color="secondary"
                                   text
                                   outlined
                                   v-on="on"
                                   @click="del(item.id)"
                                   class="ml-2" depressed x-small fab>
                                <i class="airport small-font icon-shanchu"
                                   style='font-size: 18px !important; color: #b5bac1'></i>
                            </v-btn>
                        </template>
                        <span>{{$t('delect')}}</span>
                    </v-tooltip>
                </template>
            </table-list>
        </v-container>
        <v-dialog v-model="selectdialog" width="800px">
            <v-card  color="secondary">
                <v-card-title>
                    {{$t('airport_security.preview')}}
                </v-card-title>
                <v-container class="pd-20">
                    <v-radio-group v-model="radioGroup">
                        <v-radio @click="selecttime=false" class="pb-3" value="1"
                                 :label="$t('airport_security.selectAll')"></v-radio>
                        <v-radio @click="selecttime=true" value='0'
                                 :label="$t('airport_security.selectTime')"></v-radio>
                    </v-radio-group>
                    <div v-if="selecttime">
                        <v-row>
                            <v-col cols="6">
                                <v-datetime-picker :label="$t('airport_security.start_time')" v-model="startTime"
                                                   time-format="HH:mm:ss" class="text"></v-datetime-picker>
                            </v-col>
                            <v-col cols="6">
                                <v-datetime-picker :label="$t('airport_security.end_time')" v-model="endTime"
                                                   time-format="HH:mm:ss" class="text"></v-datetime-picker>
                            </v-col>
                        </v-row>
                    </div>

                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="submitGantt" color="accent">{{$t('confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn @click="add_open" color="primary" fab class="float-btn">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" width="800px">
            <v-card  color="secondary">
                <v-card-title>{{$t(edit_mode ? 'airport_security.edit_scheduler' : 'airport_security.add_scheduler')}}
                </v-card-title>
                <v-container class="pd-30">
                    <v-row>
                        <v-col cols="6">
                            <v-select
                                    :items="airportMes"
                                    item-value="id"
                                    item-text="name"
                                    v-model="info.airport_id"
                                    :label="$t('airport_security.airport_id')"
                                    required
                            ></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select :items="car_list" item-text="number" item-value="id"
                                      v-model="info.car_id"
                                      :label="$t('airport_security.car_id')"></v-select>
                        </v-col>

                        <v-col cols="6">
                            <v-select :items="start_destination_list" item-text="group" item-value="id"
                                      v-model="info.start_point_id"
                                      :label="$t('airport_security.start_point_id')"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-datetime-picker :label="$t('airport_security.start_time')" v-model="info.start_time"
                                               time-format="HH:mm:ss" class="text"></v-datetime-picker>
                        </v-col>
                        <v-col cols="6">
                            <v-datetime-picker :label="$t('airport_security.end_time')" v-model="info.end_time"
                                               time-format="HH:mm:ss" class="text"></v-datetime-picker>
                        </v-col>
                        <v-col cols="6">
                            <v-switch v-model="info.is_end"
                                      :label="$t('airport_security.is_end')"></v-switch>
                        </v-col>
                    </v-row>

                    <v-row v-for="(item, index) in end_point_list">
                        <v-col cols="5">
                            <v-select :items="destination_list" item-text="group" item-value="id"
                                      v-model="end_point_list[index].end_point"
                                      :label="$t('airport_security.end_point_id')"></v-select>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field
                                    v-model="end_point_list[index].await_time"
                                    :label="$t('airport_security.await_time')"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-btn text v-if="index == end_point_list.length - 1" @click="plus_end_point" fab small>
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn text @click="remove_end_point(index)" fab small>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="submit" color="accent">{{$t('confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {
        getScheduler,
        editScheduler,
        delScheduler,
        addScheduler,
        getAirport,
        get_support_vehicle_all_path_data,
        getInfoList
    } from "../../../api/airport_security";
    import TableList from '@/components/TableList'

    export default {
        name: "airportSecurityScheduler",
        components: {TableList},
        data() {
            return {
                list: [],
                edit_mode: false,
                layout: null,
                search_info: {
                    pageindex: 1,
                    pagesize: 10,
                    number: '',
                    airport_id: null
                },
                end_point_list: [{await_time: '', end_point: ''}],
                table_loading: false,
                radioGroup: "1",
                selecttime: false,
                startTime: null,
                endTime: null,
                type: null,
                dialog: false,
                selectdialog: false,
                select_air_port_id: null,
                list_count: 0,
                info: {
                    airport_id: '',
                    start_point_id: '',
                    // end_point_id: '',
                    start_time: '',
                    end_time: '',
                    // await_time: '',
                    car_id: '',
                    is_end: true
                },
                typeList: [],
                typeValue: {},
                add_loading: false,
                destination_list: [],
                start_destination_list: [],
                airportMes: [],
                car_list: []
            }
        },
        async mounted() {
            this.layout = this.$route.meta.use_layout
            this.search_info.airport_id = this.$route.query.airport_id || this.$store.state.select_air_port_id
            if ( !this.search_info.airport_id) {
                this.$toast({
                    title: this.$t('setting.warning'),
                    message: this.$t('airport_security.select_none'),
                    type: 'warning',
                    duration: 2
                })
                this.$router.push('/')
                return
            }
            this.doGet()
            const {results} = await getAirport()
            this.airportMes = results
            // 获取全部的机位节点
            get_support_vehicle_all_path_data({pagesize: 999, type: 1}).then(res => {
                this.destination_list = res.results
            })
            get_support_vehicle_all_path_data({pagesize: 999, type: 3}).then(res => {
                this.start_destination_list = res.results
            })
            // 获取保障车列表
            getInfoList({pagesize: 999}).then(res => {
                this.car_list = res.results
            })
        },
        methods: {
            remove_end_point(index) {
                if (this.end_point_list.length <= 1) {
                    return this.$toast({
                        title: this.$t('error'),
                        message: this.$t('airport_security.last_none'),
                        type: 'warning'
                    })
                }
                this.end_point_list.splice(index, 1)
            },
            plus_end_point() {
                this.end_point_list.push({await_time: '', end_point: ''})
            },
            // 获取机场名称
            get_airport_name(item) {
                for (let i of this.airportMes) {
                    if (i.id === item.airport_id) {
                        return i.name
                    }
                }
                return undefined
            },
            // 获取保障车名称
            get_car_name(item) {
                for (let i of this.car_list) {
                    if (i.id === item.car_id) {
                        return i.number
                    }
                }
                return undefined
            },
            // 获取开始点位名称
            get_start_point_name(item) {
                for (let i of this.start_destination_list) {
                    if (i.id === item.start_point_id) {
                        return i.group
                    }
                }
                return undefined
            },
            // 获取结束点位名称
            get_end_point_name(item) {
                for (let i of this.destination_list) {
                    if (i.id === item.end_point_id) {
                        return i.group
                    }
                }
                return undefined
            },
            doGet(pageindex = this.search_info.pageindex) {
                this.search_info.pageindex = pageindex
                this.table_loading = true
                const closeLoading = this.$loading()
                getScheduler(this.search_info).then(res => {
                    this.list = res.results
                    this.list_count = Math.ceil(res.count / this.search_info.pagesize)
                }).finally(() => {
                    this.table_loading = false
                    closeLoading()
                })
            },
            changePagination(data) {
                this.search_info.pageindex = data.page
                // this.search_info.pagesize = data.itemsPerPage
                this.doGet()
            },
            edit(data) {
                this.edit_mode = true
                for (let i in this.info) {
                    this.info[i] = data[i]
                }
                this.end_point_list = data.end_point_list
                this.info.start_time = this.formateDate(data.start_time * 1000)
                this.info.end_time = this.formateDate(data.end_time * 1000)
                this.info.car_id = Number(data.car_id)
                this.info.id = data.id
                this.dialog = true
            },
            del(id) {
                this.$confirm({
                    title: this.$t('confirm_del'),
                    content: this.$t('airport_security.delete_ass'),
                    okText: this.$t('confirm'),
                    cancelText: this.$t('cancel')
                })
                    .then((dialog) => {
                        delScheduler(id).then(res => {
                            this.doGet()
                        })
                    })
            },
            add_open() {
                this.edit_mode = false
                for (let i in this.info) {
                    // reset value
                    if (i === 'is_end') {
                        this.info[i] = true
                        continue
                    }
                    this.info[i] = ''
                }
                this.end_point_list = [{await_time: '', end_point: ''}]
                if ('id' in this.info) {
                    delete this.info.id
                }
                // this.info.start_time = new Date()
                this.info.airport_id = this.search_info.airport_id
                this.dialog = true
            },
            submit() {
                this.add_loading = true
                for (let i in this.info) {
                    if (this.info[i] === '') {
                        return this.$toast({
                            title: this.$t('setting.warning'),
                            message: `${this.$t('airport_security.' + i)} ${this.$t('airport_security.none_total')}`,
                            type: 'warning'
                        })
                    }
                }

                for (let i of this.end_point_list) {
                    if (!i.end_point || !i.await_time) {
                        return this.$toast({
                            title: this.$t('setting.warning'),
                            message: this.$t('airport_security.message_null'),
                            type: 'warning'
                        })
                    }

                }

                const {start_time, end_time} = this.info
                this.info.is_end = Number(this.info.is_end)
                if (start_time > end_time) {
                    return this.$toast({
                        type: 'warning',
                        title: this.$t('setting.warning'),
                        message:this.$t('airport_security.message_time')
                    })
                }
                this.info.start_time = this.formateDate(start_time)
                this.info.end_time = this.formateDate(end_time)
                if (this.edit_mode) {
                    editScheduler({...this.info, end_point_list: this.end_point_list, airport_id: this.search_info.airport_id}).then(res => {
                        this.$toast({
                            title: this.$t('success'),
                            message: this.$t('airport_security.edit_success_ass')
                        })
                        this.dialog = false
                    }).finally(() => {
                        this.add_loading = false
                        this.doGet()
                    })
                } else {
                    addScheduler({...this.info, end_point_list: this.end_point_list, airport_id: this.search_info.airport_id}).then(res => {
                        this.$toast({
                            title: this.$t('success'),
                            message: this.$t('airport_security.add_success_ass')
                        })
                        this.dialog = false
                    }).finally(() => {
                        this.add_loading = false
                        this.doGet()
                    })
                }
            },
            submitGantt() {
                if (this.startTime && this.endTime) {
                    this.startTime = this.formateDate(this.startTime)
                    this.endTime = this.formateDate(this.endTime)
                }
                if (this.radioGroup === '1') {
                    this.startTime =null
                    this.endTime = null
                }
                this.$router.push({
                    path: '/airport_security_scheduler/preview',
                    query: {type: this.radioGroup, start_time: this.startTime, end_time: this.endTime}
                })
            },
            formateDate(datetime) {
                function addDateZero(num) {
                    return num < 10 ? "0" + num : num;
                }

                let d = new Date(datetime);
                let formatdatetime =
                    d.getFullYear() +
                    "-" +
                    addDateZero(d.getMonth() + 1) +
                    "-" +
                    addDateZero(d.getDate()) +
                    " " +
                    addDateZero(d.getHours()) +
                    ":" +
                    addDateZero(d.getMinutes()) +
                    ":" +
                    addDateZero(d.getSeconds());
                return formatdatetime;
            },
        },
        computed: {
            headers() {
                return [
                    {
                        text: this.$t('airport_security.airport_id'),
                        align: 'center',
                        value: 'airport_id',
                        sortable: false
                    },
                    {
                        text: this.$t('airport_security.car_id'),
                        align: 'center',
                        value: 'car_id',
                        sortable: false
                    },
                    {
                        text: this.$t('airport_security.start_point_id'),
                        align: 'center',
                        value: 'start_point_id',
                        sortable: false
                    },
                    {
                        text: this.$t('airport_security.end_point_id'),
                        align: 'center',
                        value: 'end_point_id',
                        sortable: false
                    },
                    {
                        text: this.$t('airport_security.start_time'),
                        align: 'center',
                        value: 'start_time',
                        sortable: false
                    },
                    {
                        text: this.$t('airport_security.await_time'),
                        align: 'center',
                        value: 'await_time',
                        sortable: false
                    },
                    {
                        text: this.$t('airport_security.is_end'),
                        align: 'center',
                        value: 'is_end',
                        sortable: false
                    },
                    {
                        text: this.$t('airport_security.create_time'),
                        align: 'center',
                        value: 'create_time',
                        sortable: false
                    },
                    {
                        text: this.$t('airport_security.update_time'),
                        align: 'center',
                        value: 'update_time',
                        sortable: false
                    },
                    {
                        text: this.$t('airport_security.operation'),
                        align: 'center',
                        value: 'operation',
                        sortable: false
                    },
                ]
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import '~@/assets/css/global.styl'
    .small_font
        font-size 14px !important
        padding 2px 0px

    .icon_font
        font-size 120px !important
</style>
